import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/nb';

import Table from 'components/table/Table';
import MaxWidth from 'layouts/max-width';
import { AnchorLink } from 'components/Link';
import Spacing from 'layouts/Spacing';

const Wrapper = styled.div`
	overflow-x: auto;
`;

const Status = styled.span`
	em {
		font-style: normal;
	}
`;

const Circle = styled.i`
	width: 10px;
	height: 10px;
	border-radius: 10px;
	background: ${p =>
		p.state === 'Utbygging pågår'
			? p.theme.colors.blue600
			: p.state === 'Salg pågår'
			? p.theme.colors.green500
			: p.state === 'Utbygging ferdig'
			? p.theme.colors.green700
			: p.theme.colors.grey700};
	display: inline-block;
	margin-right: 7.5px;
`;

/**
 * Represents a table "FiberDevelopment".
 * @param {array} areas - The areas in the table
 * @param {array} fields - The fields to show in the table
 * @param {boolean} hideFinished - Wether to hide finished areas or not
 * @param {...object} props - The rest of the properties for the component
 */
export default function FiberDevelopment({
	areas = [],
	fields = [],
	hideFinished = false,
	...props
}) {
	const defaultFields = [
		'Feltnavn',
		'Prosjekt-nr.',
		'Vår entreprenør',
		'Graving',
		'Oppkobling',
		'Oppussing ferdig',
		'Antall kunder',
	];

	/** If "hideFinished" is true then loop through each entry and if state
	 * is "Utbygging ferdig" and "announcementDate" exists and is older than 6 months ago then remove it from array
	 */
	if (!!areas?.length && hideFinished) {
		// Get today´s date
		const today = dayjs().startOf('day');

		areas = areas.filter(function (obj) {
			// If no "announcementDate" set or status is not "Utbygging ferdig" then escape
			if (!obj.announcementDate || obj.status !== 'Utbygging ferdig')
				return true;

			// Set variable to correct date format
			const given = dayjs(obj.announcementDate, 'YYYY-MM-DD');

			// Difference in number of months
			const diff = today.diff(given, 'month');

			return diff <= 6;
		});
	}

	// Split array into two arrays: one array for those who has "announcementDate" set and the rest into another one
	const hasAnnouncementDate = [],
		rest = [];
	if (!!areas?.length) {
		areas.forEach(item => {
			if (item.announcementDate) {
				hasAnnouncementDate.push(item);
			} else {
				rest.push(item);
			}
		});
	}

	// Sort the array with the field "announcementDate" by that date
	if (!!hasAnnouncementDate?.length) {
		hasAnnouncementDate.sort(function (a, b) {
			var dateA = a.announcementDate ? new Date(a.announcementDate) : '',
				dateB = b.announcementDate ? new Date(b.announcementDate) : '';
			return dateB - dateA;
		});
	}

	// If no fields set to be shown then use the default fields
	if (!fields?.length > 0) fields = defaultFields;

	const tableRows = [...hasAnnouncementDate, ...rest];

	return (
		<MaxWidth
			className="component__fiber-development"
			data-cy="component__fiber-development">
			<Spacing {...props}>
				<Wrapper>
					<Table>
						{tableRows?.length > 0 && (
							<>
								{fields?.length > 0 && (
									<thead>
										<tr>
											{fields?.map((field, i) => {
												return <td key={i}>{field}</td>;
											})}
										</tr>
									</thead>
								)}
								<tbody>
									{tableRows?.map((item, i) => {
										return (
											<Row
												key={item.title + '-' + i}
												item={item}
												fields={fields}
											/>
										);
									})}
								</tbody>
							</>
						)}
					</Table>
				</Wrapper>
			</Spacing>
		</MaxWidth>
	);
}

/**
 * Represents a row in the table
 * @param {object} item - The contents of the row in the table
 * @param {array} fields - The fields / columns to show
 */
function Row({ item, fields }) {
	return (
		<tr>
			{fields?.includes('Feltnavn') && (
				<td>
					{(item.prospect && (
						<AnchorLink
							target="_blank"
							rel="noreferrer"
							href={item.prospect?.file?.url}
							title={`Se prospekt for ${item.title} (.pdf)`}>
							{item.ruralFiber && 'Bygdefiber - '}
							{item.title}
						</AnchorLink>
					)) || <span>{item.title}</span>}
				</td>
			)}

			{fields?.includes('Prosjekt-nr.') && (
				<td>{item.projectNumber || <span>&nbsp;</span>}</td>
			)}

			{fields?.includes('Vår entreprenør') && (
				<td>{item.contractor || <span>&nbsp;</span>}</td>
			)}

			{fields?.includes('Graving') && (
				<td>{item.digging || <span>&nbsp;</span>}</td>
			)}

			{fields?.includes('Oppkobling') && (
				<td>{item.connection || <span>&nbsp;</span>}</td>
			)}

			{fields?.includes('Oppussing ferdig') && (
				<td>
					{(item?.renovation && item.renovationDone) || (
						<span>Info kommer</span>
					)}
				</td>
			)}

			{fields?.includes('Status') && (
				<td>
					{(item?.status && (
						<Status>
							<Circle state={item.status} />
							<em>{item.status}</em>
						</Status>
					)) || <span>&nbsp;</span>}
				</td>
			)}

			{fields?.includes('Kommune') && (
				<td>{item.municipality || <span>&nbsp;</span>}</td>
			)}

			{fields?.includes('Antall kunder') && (
				<td>{item.clientsCount || <span>&nbsp;</span>}</td>
			)}

			{fields?.includes('Kunngjøringsdato') && (
				<td>
					{(item.announcementDate &&
						dayjs(item.announcementDate).format('YYYY-MM-DD')) || (
						<span>&nbsp;</span>
					)}
				</td>
			)}
		</tr>
	);
}
